<template>
  <Card
    class="tariff"
    :color="
      item.slug === 'black' || item.slug === 'premium' ? 'black' : 'white'
    "
  >
    <div class="tariff-head">
      <div class="tariff-title">
        {{ item.name }}
        <span
          v-if="item.hit"
          class="tariff-label"
          :style="{ backgroundColor: item.hit.color }"
        >
          {{ item.hit.text }}
        </span>
      </div>
      <div class="tariff-options">
        <div v-if="item.internetPackage?.value" class="tariff-option">
          <strong v-if="item.internetPackage?.value">
            {{ item.internetPackage.value }}
            {{ item.internetPackage.uom }}
          </strong>
          <span v-if="item.internetPackage?.bonus">
            <template v-if="item.hit?.text === 'MiXX S'">
              + 50 ГБ каждый месяц в подписке MiXX S на 3 месяца
            </template>
            <template v-else>
              +{{ item.internetPackage.value }}
              {{ item.internetPackage.uom }} каждый 3-й месяц
            </template>
          </span>
        </div>
        <div v-if="item.minutes?.value" class="tariff-option">
          <strong v-if="item.minutes?.value">
            {{ item.minutes.value }} минут
          </strong>
          <span v-if="item.minutes?.frontName">
            {{ item.minutes.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
        <div v-else-if="minutesPackage" class="tariff-option">
          <strong v-if="minutesPackage?.value">
            {{ minutesPackage.value }} минут
          </strong>
          <span v-if="minutesPackage?.frontName">
            {{ minutesPackage.frontName }} и <b>безлимит</b> на t2 России
          </span>
        </div>
      </div>
      <div v-if="freeTraffic" class="tariff-features">
        <p v-html="freeTraffic.frontName"></p>
        <ul class="tariff-features__pic">
          <template
            v-for="(item, index2) in freeTraffic.icons.slice(0, 6)"
            :key="index2"
          >
            <li>
              <img
                :src="`${item.local ? '' : '//t2.ru'}${item.selectedIcon}`"
                alt=""
                width="32"
                height="32"
              />
            </li>
          </template>
          <li v-if="freeTraffic.icons.length - 6 > 0" class="more">
            +{{ freeTraffic.icons.length - 6 }}
          </li>
        </ul>
      </div>
      <div v-else-if="unlimitedTraffic?.length" class="tariff-features">
        <p>
          {{ unlimitedTraffic[0].shortFrontName || "Безлимитный трафик на" }}
          <template v-for="(item, index) in unlimitedTraffic" :key="index"
            >{{ item.frontName
            }}<span v-if="index < unlimitedTraffic.length - 1">, </span>
          </template>
        </p>
        <ul class="tariff-features__pic">
          <template
            v-for="(item, index2) in unlimitedTraffic.slice(0, 6)"
            :key="index2"
          >
            <li v-if="item.image">
              <img
                :src="`//t2.ru${item.image}`"
                alt=""
                width="32"
                height="32"
              />
            </li>
          </template>
          <li v-if="unlimitedTraffic.length - 6 > 0" class="more">
            +{{ unlimitedTraffic.length - 6 }}
          </li>
        </ul>
      </div>
      <div v-else-if="item.slug === 'enough'" class="tariff-features-unlimited">
        <div class="tariff-features-unlimited__title">Безлимит</div>
        <div class="tariff-features-unlimited__text">
          на интернет по выходным
        </div>
      </div>
    </div>
    <div class="tariff-foot">
      <div v-if="internetPackage" class="tariff-home">
        <div class="tariff-home__info">
          <span>Домашний интернет:</span>
          <span>от {{ internetPackage.summaryWeight }} Мбит/с</span>
        </div>
        <div class="tariff-home__control">
          <ToggleButton @update="showCost = !showCost" />
        </div>
      </div>
      <div class="tariff-freeze">Замораживаем цену навечно</div>
      <Button :to="item.url" class="tariff-button">
        <span>Купить</span>
        <span v-if="item.price"
          ><span class="stroke" v-if="showCost"
            >{{ item.internetTele2_home.cost + item.price }} ₽/мес</span
          >
          {{ item.price }} ₽/мес
        </span>
      </Button>
    </div>
  </Card>
</template>

<script>
import ToggleButton from "@/components/ToggleButton.vue";
import Button from "./Button.vue";
import Card from "./Card.vue";

export default {
  props: {
    item: Object,
  },
  computed: {
    unlimitedTraffic() {
      return this.item.parameters
        ?.filter((e) => e.blockAlignment === "unlimitedTraffic")
        .sort((a, b) => b.frontWeight - a.frontWeight);
    },
    freeTraffic() {
      const { containerParams } = this.item;

      if (!containerParams) {
        return null;
      }

      const icons = [
        ...(containerParams.messengers ?? []),
        ...(containerParams.socialNetworks ?? []),
        ...(containerParams.videoServices ?? []),
      ].filter((e) => e.selectedIcon);

      if (!icons.length) {
        return null;
      }

      return {
        frontName: this.item.containerParams?.internetFree || "",
        icons,
      };
    },
    internetPackage() {
      return this.item.parameters?.find((e) => e.key === "пакетИнтернета");
    },
    minutesPackage() {
      return this.item.parameters?.find(
        (e) => e.key === "пакетМинутНаДругиеМобРоссии"
      );
    },
  },
  components: {
    ToggleButton,
    Card,
    Button,
  },
  data() {
    return {
      showCost: false,
    };
  },
  methods: {
    remakeHREF(str) {
      if (str.includes("Selection")) {
        return str.replace(
          "/bolshe/selection?pageParams=view%3Dlanding",
          "https://msk.t2.ru/bolshe/selection?pageParams=view%3Dlanding"
        );
      }
      return str;
    },
    getSocial(features) {
      // remove if link fix
      const brokenString =
        '<a href="/option/4g-turbo">4G Turbo</a> и <a href="https://selection.t2.ru/">привилегии программы t2 Selection</a>';
      const broken = features.advantages.find(
        (a) => a.frontName === brokenString
      );
      if (broken) {
        broken.frontName =
          '<a href="https://t2.ru/option/4g-turbo" target="_blank">4G Turbo</a> и <a href="https://selection.t2.ru/" target="_blank">привилегии программы t2 Selection</a>';
      }
      // end remove if link fix

      const sortedByPriority = features.advantages
        .concat(features.gamingAdvantages)
        .sort((a, b) => a.priority - b.priority);
      return [
        features.messengers,
        features.socialNetworks,
        ...sortedByPriority,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  display: grid;
  gap: var(--widthGapH) var(--widthGapV);
  @include md {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tariff {
  display: grid;
  align-content: space-between;
  gap: 16px;
  @include md {
    gap: 24px;
  }
  &.black {
    color: var(--colorWhite);
    .tariff-option {
      color: #bfbfbf;
      &:deep(strong) {
        color: var(--colorWhite);
      }
    }
    .tariff-features__pic {
      li {
        background-color: var(--colorBlack);
      }
    }
  }
  &-head {
  }
  &-title {
    font-family: var(--fontSecondary);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
    font-weight: 800;
    display: inline-grid;
    grid-auto-flow: column;
    gap: 12px;
    align-items: center;
  }
  &-label {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 0 8px;
    padding-left: 28px;
    border-radius: 10px;
    font-family: var(--fontPrimary);
    font-weight: 400;
    color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='15' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M3.501.676c.97.073 1.697.556 2.114 1.38.272.539.425 1.447.498 2.012.07-.543.216-1.396.474-1.904.4-.79 1.094-1.252 2.023-1.322 1.33-.099 2.32.657 2.42 1.837.062.729-.32 1.347-.89 1.806h.256c.736 0 1.333.597 1.333 1.334v3.12H6.64V4.573H5.364V8.94H.275V5.819c0-.737.596-1.334 1.333-1.334h.3c-.599-.48-1-1.127-.935-1.89C1.077 1.362 2.113.573 3.501.676Zm-.248 1.106c.052 0 .104.002.158.006.272.021 1.1.084 1.365 1.36.047.228.108.663.18 1.296a14 14 0 0 1-1.237-.275c-.83-.246-1.643-.835-1.588-1.484.05-.573.464-.903 1.122-.903Zm5.595.12c-.049 0-.1.002-.15.005-.262.02-1.055.08-1.309 1.302a16.91 16.91 0 0 0-.17 1.24c.54-.101.936-.19 1.182-.262.795-.236 1.573-.8 1.52-1.422-.046-.548-.443-.863-1.073-.863Z' clip-rule='evenodd'/%3E%3Cpath fill='%23fff' d='M.273 10.212h5.091v4.455H1.607a1.334 1.334 0 0 1-1.334-1.333v-3.122Zm6.364 0h5.091v3.122c0 .736-.597 1.333-1.333 1.333H6.637v-4.455Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 8px 2px;
  }
  &-options {
    display: grid;
    gap: 12px;
    margin-top: 16px;
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
    &_3 {
      @include md {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  &-option {
    color: #808080;
    &:deep(strong) {
      display: block;
      font-size: var(--sizeHeading3);
      line-height: var(--lineHeightHeading3);
      font-family: var(--fontSecondary);
      font-weight: 800;
      color: var(--colorBlack);
      margin-bottom: 4px;
      white-space: nowrap;
    }
  }
  &-features {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    display: grid;
    gap: var(--widthGapV);
    justify-content: space-between;
    @include md {
      margin-top: 24px;
      font-size: var(--sizeText);
      line-height: var(--lineHeightText);
      grid-auto-flow: column;
    }
    ul {
      @include reset-list;
    }
    &__pic {
      display: flex;
      align-items: flex-start;
      li {
        + li {
          margin-left: -8px;
        }
        background-color: var(--colorWhite);
        padding: 2px;
        border-radius: 64px;
        img {
          display: block;
        }
        &.more {
          background-color: #999ea8 !important;
          width: 36px;
          height: 36px;
          color: var(--colorWhite);
          display: grid;
          place-items: center;
          font-size: 12px;
        }
      }
    }
    &-unlimited {
      display: grid;
      border-radius: 12px;
      background-color: #d9d9d96e;
      padding: 12px;
      padding-right: 140px;
      height: 108px;
      align-content: space-between;
      margin-top: 24px;
      background-image: url(~@/assets/icons/enough-home.webp);
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: 128px 108px;
      &__title {
        font-family: var(--fontSecondary);
        font-size: 20px;
        font-weight: 800;
      }
      &__text {
        color: #808080;
        font-size: 15px;
      }
    }
  }
  &-home {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    @include md {
      margin-bottom: 24px;
    }
    &__info {
      span {
        display: block;
      }
    }
    &__control {
    }
  }
  &-freeze {
    display: grid;
    height: 48px;
    align-items: center;
    background-color: #0000ff;
    padding: 0 24px;
    color: #fff;
    border-radius: 12px;
    margin-bottom: 32px;
    font-size: 12px;
    background-image: url(~@/assets/icons/fee-fixed.webp);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 48px 48px;
  }
  &-button {
    justify-content: space-between;
    &:deep(.stroke) {
      position: relative;
      font-size: 12px;
      line-height: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        border-top: 2px solid var(--colorPink);
        margin-top: -1px;
        transform: rotate(-10deg);
      }
    }
  }
}
</style>
